import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de 6 a 15 cm de diàmetre de color blanc brut a crema, convex primerament i després pla per finalment adquirir la forma d’embut. El marge és uniforme enrotllat cap a baix i una mica ondulat. Sol estar cobert de terra i de fulles que el mateix bolet ha remogut per a poder sortir. Les làmines són espaiades, poc decurrents, adnades i de color blanc. El peu és curt, de 3-6 x 2-3 cm, cilíndric i molt dur, de color blanc amb taques marronoses. Les espores són de color crema molt clar en massa, el·líptiques o quasi rodones, de 8-11 x 6-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      